html,
body {
  height: 100%;
  width: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  background-color: #e9f4ed;
  font-family: "Source Sans Pro", sans-serif;
}

body .modal-backdrop {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(159, 159, 159, 0.6);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(159, 159, 159, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade.m-content.modal.show {
  background: rgba(22, 153, 158, 0.4) 0% 0% no-repeat padding-box;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.fade.m-content.modal.show .modal-content {
  background: transparent;
  border: 0;
}

.modal-header.m-header {
  border: 0px;
  padding: 0px;
}

.modal-header.m-header .modal-title.h4 {
  background: #16999E;
  color: #fff;
  font-size: 1vw;
  border-radius: 15px 15px 0 0;
  padding: .7rem 1rem .5rem 1rem;
  margin-left: 1.2rem;
}

.modal-footer.m-footer {
  border: 0px;
  justify-content: center;
}

.d-btn.btn-cancel {
  border-radius: 25px;
  background-color: #9F9F9F;
  border: none;
  width: 25%;
  height: 50px;
}

.d-btn.btn-cancel.close-btn {
  width: 100%;
}

.d-btn.btn-submit {
  width: 25%;
  border-radius: 25px;
  height: 50px;
  background-color: #1DBF67;
}

/* to remove below */

.dispositionModal .dropdown-menu.show {
  overflow-y: scroll;
  height: 150px;
}

.dispositionModal label {
  display: block;
  font-size: 14px;
  font-weight: 700;
}

.dispositionModal.q .custom-select {
  height: 35px;
  font-size: 14px;
}

.dispositionModal .custom-select {
  width: 60%;
  background: #F0F0F0 0% 0% no-repeat padding-box;
  cursor: pointer;
  height: 40px;
}

.dispositionModal textarea.form-control {
  resize: none;
  width: 100%;
  height: 160px;
}

.dispositionModal input,
.dispositionModal textarea {
  background-color: #F0F0F0;
}

.dispositionModal.flex-container {
  display: flex;
}

.dispositionModal.flex-container .flex-elem input {
  min-width: 68%;
  height: 35px;
}

.dispositionModal.flex-container .flex-elem.amb input {
  min-width: 95%;
}

.selectFormDiv.form-group {
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
  margin-right: 15px;
}

.selectFormTextInput .form-control {
  width: 30%;
  display: inline-block;
  height: 100%;
  border-radius: 0px 4px 4px 0px
}

.selectFormDiv .down-arrow {
  position: absolute;
  top: 54%;
  right: 42%;
  color: #9F9F9F;
}

/* to remove above */

.in-t span.rs-t {
  width: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  background-color: #16999E;
  border: 0;
  border-radius: .25rem 0 0 .25rem;
  color: #fff;
}

.in-t div.s-c {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 5.3vh;
}

.in-t div.s-c input {
  border-radius: 0 .25rem .25rem 0;
  height: 100%;
  margin: 0;
}

.in-t.g-c {
  grid-column: 1/3;
}

.m-body.modal-body {
  padding: 1rem 4rem 0px 4rem;
}

.dispositionModal .react-datepicker .react-datepicker__current-month {
  padding: 8px 0px;
  background-color: #16999E;
  color: #fff;
  border-radius: 14px 14px 0px 0px;
  font-size: 13px;
}

.dispositionModal .react-datepicker .react-datepicker__header {
  padding-top: 0px;
}

.dispositionModal .react-datepicker {
  border-radius: 14px;
  box-shadow: 0px 0px 6px #00000029;
  border: none;
}

.dispositionModal .react-datepicker__navigation--next,
.dispositionModal .react-datepicker__navigation--next:hover {
  border-left-color: #fff;
  outline: none;
}

.dispositionModal .react-datepicker__navigation--previous,
.dispositionModal .react-datepicker__navigation--previous:focus,
.dispositionModal .react-datepicker__navigation--previous--disabled,
.dispositionModal .react-datepicker__navigation--previous--disabled:hover,
.dispositionModal .react-datepicker__navigation--previous:hover {
  border-right-color: #fff;
  outline: none;
}

.dispositionModal .react-datepicker__day.react-datepicker__day--selected.valid-date,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.dispositionModal .react-datepicker__day.react-datepicker__day--keyboard-selected.valid-date,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 25px;
  background-color: #f7aa28;
  color: #fff;
  outline: none;
}

.dispositionModal .react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #F7AA28;
  border-radius: 25px;
}

.dispositionModal .react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 25px;
  background-color: #D8D8D8;
}

.dispositionModal .react-datepicker__day-names {
  background-color: #E9F4ED;
  font-weight: 600;
}

.dispositionModal .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #5C8081;
}

.dispositionModal .react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.dispositionModal .react-datepicker__day.invalid-date {
  color: #5C8081;
  opacity: 0.3;
}

.dispositionModal .react-datepicker__day.valid-date {
  color: #5C8081;
}

.dispositionModal .react-datepicker__month-container {
  width: 300px;
}

.dispositionModal .react-datepicker__month {
  font-family: 'Source Sans Pro';
  font-size: 16px;
}

.dispositionModal .react-datepicker__day-names .react-datepicker__day-name {
  font-size: 0;
  width: 25px;
  height: 25px;
}

.dispositionModal .react-datepicker__day-names .react-datepicker__day-name::first-letter {
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-weight: bold;
}

.calendar-svg svg {
  width: 20px
}

span.calendar-svg {
  position: absolute;
  top: 5px;
  right: 5%;
  cursor: pointer;
}

form.dispositionModal.flex-elem.calendar .ip-div {
  position: relative;
  margin-right: 12px;
}

form.dispositionModal.flex-elem.calendar .react-datepicker__input-container {
  position: static;
}

form.dispositionModal.flex-elem.calendar .selectFormTextInput .form-control,
form.dispositionModal.flex-elem.time .selectFormTextInput .form-control {
  width: auto;
  display: inline-block;
  height: auto;
  border-radius: 4px;
}

.dispositionModal .form-control.displayed-time {
  cursor: pointer;
  background-color: #F0F0F0;
  padding-top: 5px;
}

.dispositionModal .react-datepicker .react-datepicker__header.react-datepicker__header--time--only {
  padding-top: 5px;
}

.dispositionModal .form-control.displayed-time .down-arrow {
  margin-left: 5px;
  color: #9F9F9F;
}

p.err-p {
  margin: 0;
  color: red;
  font-size: 13px;
  text-align: right;
}

/* Patient Info */
.dispositionModal .selectFormDiv.caller-city .custom-select {
  border: none;
  width: 100%;
}

.selectFormDiv.caller-city .custom-select:focus {
  outline: none;
  border: none;
  box-shadow: none
}

;

/* Hide Patient Onboard Accordian In Patient Onboard Tab */
.hide-tab {
  display: none;
}

/* tab css */
.ticketlink.nav-link {
  padding: 0px;
  margin-right: 0.2em;
}

.p-content.tab-content {
  height: 80vh;
}
