.nav-menu {
  left: -100%;
  transition: 850ms;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 0px 15px 15px 0px;
  position: relative;
  margin: 22px 0px 22px 0px;
}

.nav-menu.active {
  left: 0;
  transition: 850ms;
}

.profileIcon {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  background-color: red;
  border-radius: 50%;
  transform: translate(50%, -50%);
  // z-index: 100;
}

.c-q-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-size: 1vw;
    font-weight: 400;
    margin-right: .5rem;
    margin-bottom: 0;
  }

  .custom-control {
    z-index: 0;
  }

  .custom-switch .custom-control-label::after {
    top: 1px;
    left: -38px;
    width: 22px;
    height: 22px;
    background-color: #9F9F9F;
    box-shadow: 3px 0.5px 3px #00000029;
    border-radius: 25px;
  }

  .custom-switch .custom-control-label::before,
  .custom-control-input:not(:disabled):active~.custom-control-label::before {
    left: -2.35rem;
    width: 2.25rem;
    pointer-events: all;
    top: 6px;
    height: 12px;
    border-radius: .5rem;
    box-shadow: none;
    background-color: #F0F0F0;
    border: #F0F0F0 solid 1px;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #E9F4ED;
    background-color: #E9F4ED;
    box-shadow: none;
  }

  .custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #16999E;
    transform: translateX(0.90rem);
    box-shadow: -3px 0.5px 3px #d4ebed;
  }

  .custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #E9F4ED;
    cursor: not-allowed;
  }
}

.c-d .qb-top.btn-primary:not(:disabled):not(.disabled).active,
.qb-top.btn-primary:not(:disabled):not(.disabled):active,
.show>.qb-top.btn-primary.dropdown-toggle,
.qb-top.btn-primary:not(:disabled):not(.disabled).active:focus,
.qb-top.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.qb-top.btn-primary.dropdown-toggle:focus,
.qb-top.btn-primary.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;

  &:focus {
    color: #000;
    outline: none;
    box-shadow: none;
  }
}

.c-d .qb-menu .list-unstyled {
  max-height: 35vh;
  overflow-y: scroll;
  margin-bottom: 5px;

  .dropdown-item.qb-item {
    font-size: 1vw;
  }
}

.c-d .qb-menu.vl-list {
  max-height: 45vh;
  overflow-y: scroll;

  .qb-item {
    font-size: 1vw;
  }
}

.c-d {
  background: #F0F0f0;
  border-radius: 5px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  button.qb-top {
    font-size: 1vw;
  }
}

.ambulancenav:hover,
.ambulancenav {
  background-color: rgb(159, 159, 159);
  color: #fff;
  margin-right: 5px;
  border-radius: 20px 20px 0px 0px;
  font-weight: 600;
  font-size: 1vw;
  height: 5vh;
}

.ambulancenavactive:hover,
.ambulancenavactive {
  background-color: rgb(22, 153, 158);
  color: #fff;
  margin-right: 5px;
  border-radius: 20px 20px 0px 0px;
  font-weight: 600;
  font-size: 1vw;
  height: 5vh;
}