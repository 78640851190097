.ticketProgressBar{
  height: 7vh;
  margin: auto;
  width: 90%;
  &.orange {
    .RSPBprogressBar{
      margin: 1vw;
      height: 0vh;
      background-color: transparent;
      border: none;
      border-top: 2px dashed #FEA729;
      .RSPBprogression {
        top: -2px;
        border-top: 2px solid #FEA729;
      }
    }
    .indexedStep {
      color: #FFFFFF;
      width: 1.2vw;
      height: 1.2vw;
      font-size: 1vw;
      background-color: #FFFFFF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #F7AA28;
      &.accomplished {
        background-color: #F7AA28;
        color: #F7AA28;
        border: 2px solid #F7AA28;
      }
      &.current-step {
        background-color: #FDEFDA;
        color: #FDEFDA;
      }
    }
  }
  &.green {
    .RSPBprogressBar{
      margin: 1vw;
      height: 0vh;
      background-color: transparent;
      border: none;
      border-top: 2px dashed rgba(29, 191, 103, 1);
      .RSPBprogression {
        top: -2px;
        border-top: 2px solid rgba(29, 191, 103, 1);
      }
    }
    .indexedStep {
      color:rgba(255, 255, 255, 1);
      width: 1.2vw;
      height: 1.2vw;
      font-size: 1vw;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(159, 159, 159, 1);
      &.accomplished {
        background-color: rgba(29, 191, 103, 1);
        color: rgba(29, 191, 103, 1);
        border: 2px solid rgba(64, 217, 128, 1);
      }
      &.current-step {
        background-color: rgba(211, 234, 228, 1);
        color: rgba(211, 234, 228, 1);
        border: 2px solid rgba(29, 191, 103, 1);
      }
    }
  }
  .step{
    position: relative;
    cursor: pointer;
    span{
      font-size: 0.8vw;
      font-weight: 600;
      position: relative;
      white-space: nowrap;
      position: absolute;
      top: 3vh;
      &.label-t {
        top: 5vh;
      }
      &.label-t.info{
        left: -1.5vw;
      }
      &.info{
        left: -0.8vw;
      }
      &.quote{
        left: -2vw;
      }
      &.quote.tripstart{
        left: -1vw;
      }
      &.quote.tripstart.label-t{
        left: -1.5vw;
      }
      &.closed{
        right: -2vw;
      }
      &.closed.reachedpickup, &.closed.reacheddrop{
        right: -4vw;
      }
      &.closed.reachedpickup.label-t, &.closed.reacheddrop.label-t{
        right: -4vw;
      }
      &.feedback{
        right: -0.8vw;
      }
      &.feedback.payment{
        right: -1vw;
      }
      &.feedback.payment.label-t{
        right: -2.8vw;
      }
      &.done {
        color: rgba(22, 153, 158, 1);
      }
      &.current {
        color: #343434;
      }
      &.incomplete{
        color: #9F9F9F;
      }
    }
  }
}