div.cc-u{ 
  margin: 0;
  padding: .5rem;
  background-color: #16999e;
  border-radius: 15px 15px 0 0;
  font-size: 1vw;
  color: #f0f0f0;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  img.cc-ui{ 
    height: 4vh;
    border: 2px solid #f0f0f0;
    margin-right: 0.5rem;
  }
}

div.cc-n.s-c{
  background-color: #fff;
  border-bottom: 1px solid #9F9F9F;
  align-items: center;
}


div.cc-n{
  display: flex;
  margin: 0;
  background-color: #D4EBED;
  color: #16999e;
  font-size: 1vw;
  font-weight: 600;
  justify-content: space-between;
  padding: 0.3rem .5rem;
  // margin-bottom: 0.5rem;
}