.formFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputContainer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 17px;
}

.forgotPasswordDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
}

.forgotPasswordText {
  font-size: 13px;
  color: #346ccc;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.loginButton {
  cursor: pointer;
  background-color: #1dbf67;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 30px;
  padding: 15px 20px 15px 10px;
  height: 60px;
  min-width: 210px;
  margin-top: 50px;
  span {
    padding-left: 32px;
  }
}
