@mixin rings($duration, $delay) {
  opacity: 0.35;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  left: -8px;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 2px solid #40d980;
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
}

.request-loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
  width: 35vh;
  border-radius: 100%;
  background: transparent;

  span {
    position: relative;
    font-size: 4vw;
    font-weight: 700;
    right: 0.65vw;
    text-align: center;
    width: 15vw;
    overflow-wrap: break-word;
    margin: auto;
    color: #40D980;
  }

  &::after {
    @include rings(3s, 0s);
  }

  &::before {
    @include rings(3s, 0.5s);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}
