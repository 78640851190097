/* .selectFormDiv.caller-city .down-arrow {top: 22%;right: 5%;};
.selectFormDiv.caller-city .custom-select:active{outline: none};
.dispositionModal .selectFormDiv.caller-city .custom-select{border: none;}
.ticketTab.tab-pane{padding: 5px 10px;background-color: #fff;box-shadow: 0px 4px 16px #00000029;} */

.in-t label,
.a-it label {
  font-size: 1vw;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0.3rem;
}

.in-t div.form-group {
  margin-bottom: 0.5rem;
}

.in-t input.form-control:focus,
.in-t select.custom-select:focus,
.in-t textarea.form-control:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #ccc;
  background-color: #fff;
}

.in-t input.form-control {
  height: 5.3vh;
  margin-bottom: 0.5rem;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  font-size: 1vw;
}

.in-t textarea.form-control {
  resize: none;
  height: 12vh;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  font-size: 1vw;
}

.inv-c-wrapper .in-t textarea.form-control {
  height: 15vh;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.in-t select.custom-select {
  height: 5.3vh;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border-radius: 2px;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  margin: 0;
  font-size: 1vw;
}

.in-t select.custom-select option {
  font-size: 1vw;
}

.in-t.mb-1 select {
  margin-bottom: 1.6rem;
}

.in-t .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%2316999E' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}

.in-t .form-check-input:checked {
  background-color: #e9f4ed;
  border-color: #d3eae4;
}

.in-t .form-check-input:focus {
  border-color: #d3eae4;
  outline: 0;
  box-shadow: none;
}

.in-t.c-in-t:first-child {
  margin-right: 1rem;
}

.a-it span.input-group-text {
  display: inline;
  padding: 0;
  margin: 0;
  line-height: 0;
  font-size: 0;
  font-weight: 100;
  background: transparent;
  border: none;
  cursor: pointer;
}

.a-it form {
  margin-right: 0.5rem;
}

.a-it .c-a-it .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.a-it.f-trip .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.a-it .c-a-it .form-group label {
  margin: 0 0.3rem 0.15rem 0;
  font-size: 0.9vw;
  font-weight: 400;
  color: #5c8081;
}

.a-it.f-trip div.form-group {
  width: fit-content;
  margin: 0.5rem auto;
  justify-content: space-around;
  float: left;
  margin-top: 12px;
  padding: 6px;
}

.a-it.f-trip div.form-group label {
  margin-left: 0.5rem;
}

.r-f {
  position: relative;
}

div.a-it.s-d {
  position: absolute;
  top: 6vh;
  right: 1vw;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

div.a-it.s-d label {
  margin-bottom: 0.1rem;
  margin-right: 0.3em;
  font-size: 0.9vw;
  font-weight: 400;
}

.vr {
  display: inline-block !important;
  margin-top: 0 !important;
}


.a-it.addon div.form-group label {
  margin-right: 0.5rem;
  font-size: 0.9vw;
  font-weight: 400;
  color: #5c8081;
}

.a-it.addon div.form-group {
  width: fit-content;
  margin: 0.5rem auto;
  justify-content: space-around;
  float: left;
  margin-top: 5px;
  padding: 6px;
}

